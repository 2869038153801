<template>
    <div class="content">
        <!-- 头部 -->
        <div class="advan">
            <img class="adv" src="../assets/img/adv.png">
            <div class="tage">
                <!-- 优势-->
                <div class="vant">
                    <div class="allwant">
                        <div :key="index" class="item" v-for="(item,index) in bming">
                            <img :src="$url+item" alt="" class="da" v-if="!item.title">
                            <div class="xiao" v-else>
                                <img :src="$url + item.image" alt="">
                                <span>{{item.title}}</span>
                                <div>
                                    <p :key="index" v-for="(desc,index) in item.desc">{{desc}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="major">
                    <div class="line"></div>
                    <div :key="index" @click="gopage(item.id)" :class="['items',index%2 == 0?'left':'right']" v-for="(item,index) in report" >
                        <img :src="$url+item.image">
                        <div class="word1">
                            <h1 :title="item.title">{{item.title}}</h1>
                            <span @click="gopage(item.id)" class="more">查看更多</span>
                        </div>
                    </div>
                </div>
            </div>

            <img class="team" src="../assets/img/team.png">
            <div class="goodteam">
                <div :key="index" class="teamlist" v-for="(item,index) in team" :ref="'show'+index">
                    <img :src="$url+item.image" class="teamimg">
                    <div class="words">
                        <span>主任： {{item.username}}</span>
                        <span>{{item.experience}}</span>
                        <p v-html="item.content"></p>
                    </div>


                    <div class="showButton" :ref="'button'+index" @click="showView(index)">
                        展开
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
    export default {

        name: "advantage",
        data() {
            return {
                report: [],
                team: [],
                tage: [],
                bming: []
            }
        },
        methods:    {
            //优势

            getdavantage() {
                this.$get('index/advantage')
                    .then(res => {
                        const data = []
                        for (let i in res.data) {
                            data.push(res.data[i].image)
                            data.push(
                                {
                                    image: res.data[i].icon_image,
                                    title: res.data[i].title,
                                    desc: res.data[i].desc.split(',')
                                }
                            )
                        }
                        this.bming = data

                    })
            },
            //优秀团队
            getteam() {
                this.$get('index/team')
                    .then(res => {
                        this.team = res.data
                    })
            },
            //新闻接口
            getnews() {
                this.$get('index/news?type=2&limit=4')
                    .then(res => {
                        this.report = res.data.data
                    })
            },
            //新闻跳转
            gopage(id) {
                this.$router.push({path: '/exchangeView', query: {id: id, type: 2}})
            },
            showView(index){
                if (this.$refs[`button`+index][0].innerHTML == '收起'){
                    this.$refs[`show`+index][0].style.height = '10.7396vw'
                    this.$refs[`button`+index][0].innerHTML = '展开'
                    this.$refs[`button`+index][0].style.color = 'black'
                }else{
                    this.$refs[`show`+index][0].style.minHeight = '10.7396vw'
                    this.$refs[`show`+index][0].style.height = 'auto'
                    this.$refs[`button`+index][0].innerHTML = '收起'
                    this.$refs[`button`+index][0].style.color = '#009fa8'
                }


            }
        },
        created() {
            this.getteam()
            this.getnews()
            this.getdavantage()
        }
    }
</script>

<style lang="less" scoped>

    .content {
        max-width: 100vw;
        min-height: 100vh;
    }

    .adv {
        height: 7.8125vw;
        width: 100%;
        margin: auto;
    }

    .tage {
        /*height:50.6250vw;*/
        background: white;
        /*background-image: url("../assets/images/wbanner.png");*/
        /*background-repeat: no-repeat;*/
        /*background-size: cover;*/
        /*overflow: hidden;*/
    }

    .vant {
        height: auto;
        background-image: url("../assets/images/wbanner.png");
        background-repeat: no-repeat;
        background-size: cover;

        .allwant {
            width: 70.0521vw;
            height: 32.4479vw;
            padding: 3.4896vw 0;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;

            .item {
                width: 14.0104vw;
                height: 9.3750vw;

                .da {
                    width: 100%;
                    height: 100%;
                }

                .xiao {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                    img {
                        width: 2.6563vw;
                        height: 2.6563vw;
                        border-radius: 50%;
                        transition: all 1s;
                    }

                    img:hover {
                        transform: scale(1.2) rotate(360deg);
                    }

                    span {
                        font-size: 1.0417vw;
                        font-weight: bold;
                        color: #333333;
                        line-height: 1.6667vw;
                    }

                    p {
                        font-size: 0.7292vw;
                        font-weight: 550;
                        color: #666666;
                        line-height: 1.4583vw;
                    }

                }
            }
        }

    }


    .now {
        font-size: 0.7292vw;
        font-weight: 550;
        color: #666666;
        line-height: 1.7188vw;
    }

    .major {
        /*height: 11.4063vw;*/
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 18.75vw;
        width: calc( 100% - 18.75vw - 18.75vw);
        position: relative;

    }


    .major .items {
        display: flex;
        flex-direction: row;
        height: 5.2083vw;
        width: 50%;
        overflow: hidden;
        margin-bottom: 0.9896vw;
        cursor: pointer;

    }
    .left{
        padding-right: 3.6458vw;
    }

    .right{
        padding-left: 3.6458vw;
    }

    .major .items img {
        width: 9.3750vw;
        height: 5.2083vw;
        margin-right: 0.9896vw;
    }

    .word1 {
        text-align: left;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 5.2083vw;
    }

    .major .items h1 {
        flex: 1;
        width:15.6250vw;
        font-size: 1.0417vw;
        line-height: 1.1vw;
        margin-bottom: 0.7292vw;
        font-weight: bold;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        color: #333333;
    }

    .major .items a {
        text-align: left;
        color: #666666;
        font-size: 0.7292vw;
        border-bottom: 0.0521vw solid #666666;


    }

    .team {
        height: 7.8125vw;
        width: 100%;
        margin: auto;
        margin-top: 4.4792vw;
    }

    .goodteam {
        width: 100%;
        background: white;
        /*height: 46.9271vw;*/
        padding-left: 18.7500vw;
        padding-right: 18.7500vw;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /*overflow: hidden;*/
        padding-bottom: 3vw;

    }

    .teamlist {
        display: flex;
        flex-direction: row;
        margin-top: 3.5937vw;
        margin-bottom: 0;
        height: 10.7396vw;
        overflow-y: hidden;
        position: relative;
        transition: height 1s;
    }

    .words {
        margin-left: 5.5729vw;
        margin-top: 0.1042vw;

    }

    .words span {
        margin-right: 1.0417vw;
        font-size: 1vw ;
        font-weight: 550;
        color: #333333;
    }

    .words p {
        min-height: 5.8333vw;
        font-size: 0.8333vw;
        color: #666666;
        margin-top: 0.8229vw;
        text-align: left;
    }
    .words p /deep/ span{
        font-size: 0.8333vw !important;
        font-weight: 500;
        color: #666666 !important;
        line-height: 1.6667vw;
    }

    .teamimg {
        width: 8.5417vw;
        height: 9.7396vw;
    }

    .line {
        width: 0.0521vw;
        height: 13.5863vw;
        background: #E6E6E6;
        position: absolute;
        left: 50%;
    }

    .more {
        width: 3.8vw;
        font-size: 0.7292vw;
        font-weight: 550;
        color: #666666;
        line-height: 1.6667vw;
        cursor: pointer;
        border-bottom: 0.0521vw solid #666666;
    }
    .showButton{
        position: absolute;
        bottom: 10px;
        right: 0;
    }



</style>
